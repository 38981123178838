.nb-font-feature-settings-tnum {
  font-feature-settings: "tnum";
}

.table.nb-virtual-table {
  font-feature-settings: "tnum";
  .thead .th {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6;
  }

  .thead-light .th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }

  .td,
  .th {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
    background-color: white;
    display: flex;
    align-items: center;
  }

  .th {
    cursor: default;
    font-weight: bold;
  }
}

.table-bordered.nb-virtual-table {
  .th,
  .td {
    border: 1px solid #dee2e6;
  }
}

.table.nb-virtual-table.nb-data-table {
  border-right: 1px solid #dee2e6;
  font-size: 14px;

  .th,
  .td {
    border-top: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;

    &:last-child {
      border-right: 1px solid #dee2e6;
    }
  }

  .tr:last-child {
    .th,
    .td {
      border-bottom: 1px solid #dee2e6;
    }
  }

  .td,
  .th {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}

// HACK -- put in an empty placeholder space to take place
// HACK -- of the react-base-table sort indicator that
// HACK -- gets undrawn when a column is not sorted
.nb-base-table-sort-placeholder {
  display: inline-block;
  .NorthbeamBaseTable__header-cell--sorting &,
  .NorthbeamBaseTable__header-cell:hover & {
    display: none;
  }
}

.nb-overview-page-table {
  .NorthbeamBaseTable__row-cell,
  .NorthbeamBaseTable__header-cell,
  .NorthbeamBaseTable__row:last-child .NorthbeamBaseTable__row-cell,
  .NorthbeamBaseTable__header-cell:last-child {
    border: none;
  }

  .NorthbeamBaseTable__table-frozen-left
    .NorthbeamBaseTable__row-cell:first-child {
    border: none;
    // border-right: 2px solid #eeeeee;
  }

  .NorthbeamBaseTable__header-row,
  .NorthbeamBaseTable__row {
    border-bottom: 2px solid #eeeeee;
  }
}

.nb-fact-column-header-parent {
  line-height: 1;
}
