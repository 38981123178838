.nb-date-picker {
  @apply relative pb-0.5;

  & > .calendar {
    @apply absolute top-1 left-1;
    .DateRangePickerInput,
    .SingleDatePickerInput {
      @apply bg-transparent;

      .DateInput,
      .DateInput_input,
      .DateRangePickerInput_arrow {
        @apply bg-transparent invisible h-0;
      }
    }
  }

  .date-picker-button {
    @apply shadow-none min-h-[38px] p-0 bg-white outline-1 outline w-full rounded-md #{!important};

    & > .date-picker-content {
      @apply flex shrink-0 items-center self-stretch;

      .date-text {
        @apply grow py-0.5 px-2.5 text-left font-normal;
      }

      .date-indicator {
        @apply py-1 px-2.5 flex border-left;
      }
    }

    &.active {
      @apply outline-active outline-3 #{!important};

      .date-indicator {
        color: hsl(0, 0%, 40%);
      }
    }

    &:not(.active) {
      outline-color: #ced4da;

      .date-indicator {
        outline-color: #ced4da;
        color: #ced4da;
      }
    }
  }
}

.data-export-date-picker {
  @apply text-[0.6rem] text-gray-500;

  .nb-date-picker {
    .date-picker-button {
      @apply min-h-[30px] font-normal outline-0 border border-[#CCCCCC] px-2 #{!important};

      & > .date-picker-content {
        @apply flex items-center;

        .date-text {
          @apply flex-1 leading-4 px-0 py-0;
        }

        .date-indicator {
          @apply border-0 py-0 px-0;
        }
      }
    }
  }
}
