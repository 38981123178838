@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .nb-toast-container {
    @apply inset-0;
    width: calc(100vw - 16px);

    .nb-toast {
      @apply w-full max-w-[unset] ml-[74px] rounded-none bg-slate-200 px-2 py-1;

      & > div {
        @apply justify-start;
      }
    }
  }

  input[type="checkbox"] {
    @apply h-4 w-4 rounded-[4px] bg-[#0075FF] text-white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);

    &:focus {
      @apply outline-none;
    }

    &:indeterminate {
      &::before {
        @apply h-4 w-4 rounded-[4px] bg-[#367BF3] content-[""] block;
      }

      &::after {
        @apply w-2.5 rounded-[4px] content-[""] text-white;
        border: 1px solid white;
        position: absolute;
        top: 21px;
        left: 15px;
      }
    }
  }
}

@layer utilities {
  .absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .absolute-vertical-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .absolute-horizontal-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .flex-center {
    @apply flex items-center justify-center;
  }

  .flex-vertical-center {
    @apply flex justify-center;
  }

  .flex-horizontal-center {
    @apply flex items-center;
  }

  .elevation-1 {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  }

  .elevation-2 {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  }
}

$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 800px,
  lg: 1280px,
  xl: 1440px,
);

$container-max-widths: (
  md: 740px,
  lg: 1140px,
  xl: 1380px,
);

@import url("https://fonts.googleapis.com/css?family=Open+Sans:98,400,500,600&display=swap");
@import url("https://rsms.me/inter/inter.css");
@import "~@fontsource/open-sans-condensed/index.css";
@import "~@fontsource/open-sans-condensed/700.css";
$font-family-sans-serif: "Inter var", "Open Sans",
  // Safari for macOS and iOS (San Francisco)
  -apple-system,
  // Chrome < 56 for macOS (San Francisco)
  BlinkMacSystemFont,
  // Windows
  "Segoe UI",
  // Android
  "Roboto",
  // Basic web fallback
  "Helvetica Neue",
  Arial, sans-serif,
  // Emoji fonts
  "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol" !default;

$font-weight-light: 100;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-bolder: 600;
$headings-font-weight: 400;
$btn-disabled-opacity: 0.5;

@import "./bootstrap-modules.scss";
@import "rc-slider";
@import "~@fortawesome/fontawesome-pro/css/all.css";

@import "./breakdown-selector.scss";
@import "./chart-tooltip.scss";
@import "./control-center.scss";
@import "./custom-switch.scss";
@import "./dropdown-button.scss";
@import "./force-webkit-scrollbar.scss";
@import "./google-ad-renderer.scss";
@import "./month-interval-picker.scss";
@import "./react-autosuggest.scss";
@import "./react-base-table.scss";
@import "./tooltip.scss";
@import "./virtual-table.scss";
@import "./raqb-bootstrap.scss";
@import "./waiting-interstitial.scss";
@import "./path-element.scss";
@import "./incidents-page.scss";
@import "./navigable-page.scss";
@import "./tab-link.scss";
@import "./onboarding.scss";

@import "./date-picker.scss";
@import "./table.scss";
@import "./buttons.scss";
@import "./input.scss";
@import "./fonts.scss";

// TODO: think about whether to apply this for H2, H3, etc.
h1 {
  font-weight: 600;
}

// All temp for the design refresh
.enableDesignRefresh {
  font-family: "Commons Pro";

  .border-primary {
    @apply border-cosmic #{!important};
  }

  [class^="custom-switch-"] {
    .slider {
      @apply bg-cosmic #{!important};
    }
  }

  table,
  .NorthbeamBaseTable__table {
    font-family: "Commons Pro Mono";
  }

  table tr th,
  .Name,
  .NorthbeamBaseTable__table-frozen-left,
  .NorthbeamBaseTable__header-cell,
  .NorthbeamBaseTable__row-cell [title="Grand Total"] {
    font-family: "Commons Pro";
  }

  a {
    all: unset;
    cursor: pointer !important;
  }
  .container-fluid {
    @apply px-0;
  }
  .navbar-drawer {
    & > a,
    & > span {
      &:hover {
        @apply bg-cosmic-3 cursor-pointer;
      }
    }
  }
  .enableDesignRefresh-overview-charts {
    @apply bg-[unset] #{!important};
    & > .col > .row {
      // Update these overview charts to css grids instead of bootstrap
      @apply grid grid-cols-2 grid-rows-2 gap-4;

      & > .elevation-1 {
        @apply bg-white;

        &.col-6 {
          max-width: unset;
          margin-bottom: unset !important;
        }
      }
    }
  }

  input[type="checkbox"] {
    @apply h-3.5 w-3.5 rounded-sm accent-cosmic bg-cosmic text-white #{!important};

    &[disabled] {
      @apply opacity-70;
    }

    &:hover {
      @apply bg-cosmic accent-cosmic;
      // cosmic
      box-shadow: 0 0 16px 4px #f3f5ff;
    }

    &:indeterminate {
      &::before {
        @apply bg-cosmic accent-cosmic content-[""] block;
      }

      &::after {
        @apply w-2.5 rounded-sm content-[""] text-white bg-cosmic accent-cosmic;
        border: 1px solid white;
        position: absolute;
        top: 21px;
        left: 15px;
      }
    }
  }

  input[type="radio"] {
    @apply h-3.5 w-3.5 bg-cosmic accent-cosmic #{!important};

    &[disabled] {
      @apply opacity-70;
    }

    &:hover {
      @apply bg-cosmic accent-cosmic;
      // cosmic
      box-shadow: 0 0 16px 4px #f3f5ff;
    }
  }
}

.rc-tooltip {
  z-index: 3000 !important;
}

body {
  background-color: white;
  overflow-y: hidden;
  overflow-x: hidden;
}

.overflow-allowed {
  overflow-x: auto;
  overflow-y: auto;
  padding-right: 0px;
  padding-left: 0px;
}

.overflow-if-necessary {
  overflow: auto;
  max-height: 100%;
}

.collapse-arrow {
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;
}

.right-auto {
  right: auto;
}

$navbar-hover-border-color: scale-color($light, $lightness: -20%);

.btn.btn-link {
  border: 0px solid transparent;
  text-align: left;
}

$navbar-color: #030b24;

.nav-footer {
  background-color: scale-color($navbar-color, $lightness: 10%);
}

.nav-table {
  @apply text-lg;

  i {
    @apply block mb-2;
  }

  a,
  span {
    @apply text-white py-3 block no-underline font-medium leading-none cursor-pointer;

    &.active {
      background-color: #285cb6;
    }

    &:hover {
      background-color: #1a3b75;
    }
  }
}

.northbeam-header-logo {
  min-width: 207px;
  max-width: 207px;
  min-height: 40px;
  max-height: 40px;
  margin-top: 12px;
  margin-left: 12px;
}

.logo {
  font-family: "Open Sans Condensed";
  color: #acd9f8;
  margin: 0px;
  font-weight: 700;
  background-color: $navbar-color !important;
  padding-left: 0.25em;
  padding-right: 0.25em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  line-height: 1em;
  font-size: 36px;
}

.bg-dark {
  background-color: $navbar-color !important;
}

.color-light {
  color: $light !important;
}

.vertical-navbar {
  position: fixed;
  left: 0;
  max-width: 90px;
  min-width: 90px;
  z-index: 2000;
}

.horizontal-navbar {
  position: fixed;
  left: 0;
  max-height: 64px;
  min-height: 64px;
  z-index: 2000;
}

.horizontal-navbar .right-section {
  max-height: 64px;
  min-height: 64px;
  margin-top: auto;
  margin-bottom: auto;
}

.horizontal-navbar .impersonate-user-dropdown {
  min-width: 200px;
  max-width: 200px;
  margin-top: 12px;
}

.navbar-sandwich {
  max-height: 362px;
}

.navbar-alarm-bell {
  max-height: 156px;
}

.navbar-admin {
  margin-bottom: 60px;
  margin-left: 90px;
  max-width: 340px;
  max-height: 200px;
}

.navbar-drawer {
  @apply text-dark h-[unset] mt-16 text-lg #{!important};

  & > a,
  & > span {
    @apply block p-2.5 border-b-2 text-inherit no-underline font-normal;

    &:last-child {
      @apply border-b-0;
    }

    &:hover {
      background-color: scale-color($black, $lightness: 90%);
    }
  }
}

.icon-large {
  font-size: 1.5rem;
}

.navbar-dark {
  background-color: $navbar-color;
  color: white;

  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: scale-color($navbar-color, $lightness: 20%);
  }
}

.navbar-light {
  background-color: #c2847a;
  color: invert($color: $light);

  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: scale-color(invert($color: $navbar-color), $lightness: 20%);
  }
}

.primary-nav-link {
  color: white;
}

.dropdown-header {
  color: scale-color($light, $lightness: -20%);
}

.card-header-tabs .nav-link {
  padding: 0.5rem 0.5rem;
}

.nb-sortable-helper {
  cursor: grab;
  z-index: 1000;
}

.title-slide {
  height: 100%;
}

.stick-to-top {
  z-index: 1;
  position: sticky;
  top: 0;
}

table ul {
  padding-inline-start: 2em;
  margin-bottom: 0.5em;
}

.card-body,
.card-header {
  padding: 1em;
}

.nb-small-react-select {
  font-size: 14px;
}

.nb-small-font {
  font-size: 14px;
}

a,
button,
a.btn,
button.btn {
  font-weight: 500;
}

.nb-metrics-carousel .carousel {
  .slide {
    background: transparent;
  }

  .control-arrow {
    // hack
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    opacity: 0.8;
    background: var(--gray);

    &:hover {
      background: var(--gray);
    }
  }

  .control-dots,
  .carousel-status {
    display: none;
  }
}

.nb-print-block {
  break-inside: avoid;
}

kbd {
  background-color: scale-color($black, $lightness: 50%);
}

.modal-backdrop {
  background-color: scale-color($black, $lightness: 50%);
  opacity: 0.5;
}

.modal-dropshadow {
  border-color: scale-color($dark, $lightness: 50%);
  box-shadow: 0 0 0 1px scale-color($dark, $lightness: 62.5%), 0 4px 11px scale-color($dark, $lightness: 62.5%);
}

.nb-freshness-meter {
  font-weight: 500;
  color: #acd9f8;
}

.nb-scrollable-dropdown {
  max-height: 600px;
  overflow-y: scroll;
}

.nb-cell-hover {
  font-weight: 700;
  text-decoration-style: dashed;
  cursor: help;
}

.alarm-bell {
  position: relative;
}

.alarm-bell:after {
  content: "";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  background: red;
  top: 0;
  right: 0;
  border-radius: 50%;
}

.alert-dot {
  position: relative;
}

.alert-dot:after {
  content: "";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  background: #ffad05;
  top: 0;
  right: 0;
  border-radius: 50%;
}

.secondary-font-purple {
  color: #2b3674;
}

.modal-dropshadow-overlay {
  background-color: white;
  border-radius: 4;
  padding: 1em;
  margin-top: 8;
  position: fixed;
  min-width: 500px;
  z-index: 1002;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#chartjs-annotation-tooltip {
  @apply rounded-md text-xs text-white text-center pointer-events-none absolute transition-all py-1 px-2;
  background: rgba(0, 0, 0, 0.8);
  min-width: 60px;

  &:before {
    @apply absolute left-1/2 h-3 w-3;
    content: "";
    top: 98%;
    background: rgba(0, 0, 0, 0.8);
    transform: rotate(180deg) translate(45%);
    clip-path: polygon(0% 100%, 50% 60%, 100% 100%);
  }
}

.react-toast-notifications__container {
  z-index: 2002 !important;
}
