.nb-path-element {
  display: inline-block;
}

.nb-path-elements-unordered {
  .nb-path-element {
    &::after {
      font-family: "Font Awesome 6 Pro";
      font-weight: 900;
      color: #aaaaaa;
      content: "\f067";
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }

    &:last-child::after {
      margin: 0;
      content: "";
    }
  }

  .nb-path-element-expander {
    cursor: pointer;
  }
}

.nb-path-elements {
  .nb-path-element {
    &::after {
      font-family: "Font Awesome 6 Pro";
      font-weight: 900;
      color: #aaaaaa;
      content: "\f105";
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }

    &:last-child::after {
      margin: 0;
      content: "";
    }
  }
}
