$table-prefix: NorthbeamBaseTable;

$table-font-size: 14px;
$table-padding-left: 0.75rem;
$table-padding-right: 0.75rem;
$column-padding: 0.75rem;
$header-background-color: #fbfcf9;

$show-frozen-columns-shadow: false;

@import "~react-base-table/es/_BaseTable.scss";

.#{$table-prefix} {
  color: #212529;
  &__header-cell {
    border-bottom: 1px solid #dee2e6;
  }

  &__header-cell-text {
    white-space: nowrap;
  }

  &__row-cell,
  &__header-cell {
    border-top: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    position: relative;
    padding: 0;

    &:last-child {
      border-right: 1px solid #dee2e6;
    }
  }

  &__row,
  &__header-row {
    border-bottom: none;

    &:last-child .#{$table-prefix}__row-cell {
      border-bottom: 1px solid #dee2e6;
    }
  }

  &__header-row {
    font-weight: 500;
  }

  &__table-frozen-left {
    &__row-cell,
    &__header-cell {
      &:last-child {
        border-right: none;
      }
    }
  }
}

// .BaseTable__expand-icon {
//   display: none;
// }

.table-cell-disabled {
  &:before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    background-color: $header-background-color;
  }
}
