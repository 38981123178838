.tab-link {
  padding: 0.5rem 1rem;
  line-height: 1.5;
  font-size: 1rem;
  display: inline-block;
  position: relative;
  border: none !important;
  background-color: transparent;
  color: var(--blue);
  border-radius: 2px;

  &:active,
  &:focus {
    border: none;
    background-color: transparent;
    outline: none;
  }

  &:hover {
    text-decoration: none;
  }

  &.active::after {
    content: "";
    height: 4px;
    border-radius: 2px;
    width: 80%;
    background-color: var(--blue);
    position: absolute;
    bottom: 2px;
    left: 0px;
    transform: translateX(12.5%);
  }

  &.tab-link-right.active::after {
    width: 4px;
    height: 100%;
    bottom: unset;
    left: unset;
    top: 0px;
    right: 0px;
    transform: unset;
  }
}

.tab-link-group-vertical {
  .tab-link {
    display: block;
    width: 100%;
    text-align: left;
  }

  .tab-link-right {
    padding-left: 0.25rem;

    &.active {
      background-color: lighten($color: $blue, $amount: 42.5%);
    }
  }
}
