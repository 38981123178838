.nb-preview-wrapper-wrapper {
  display: inline-block;
  .nb-preview-wrapper {
    transform-origin: center center;
    top: 50%;
    left: 50%;
    display: inline-block;
    position: relative;

    iframe {
      display: inline-block;
      left: 50%;
      position: relative;
      transform: translate(-50%);
    }
  }
}

.adwords-text-ad {
  color: #333;
  background-color: white;
  font-family: arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  padding: 11px;
  min-width: 240px;

  .headline {
    line-height: 18px;
    padding: 0px;
    margin-bottom: 5px;
    margin-top: 5px;
    font-weight: normal;
  }

  .url-line {
    color: #006621;
    margin-bottom: 0;
    white-space: nowrap;

    .ad-tag {
      background-color: #fff;
      border-radius: 2px;
      color: #006621;
      display: inline-block;
      font-size: 12px;
      border: 1px solid #006621;
      padding: 0 2px 0 2px;
      line-height: 14px;
      vertical-align: baseline;
      margin-left: 0;
      margin-right: 5px;
    }

    .display-url {
      vertical-align: bottom;
    }
  }

  .description {
    color: #545454;
  }
}

.adwords-gmail-ad {
  .section-frame {
    width: 329px;
    border: none;
    background-color: white;
    padding: 10px;
    overflow: hidden;

    .teaser-frame {
      white-space: nowrap;
      overflow: hidden;
      position: relative;

      .logo-container {
        display: block;
        float: left;

        img {
          max-width: 40px;
          max-height: 40px;
          vertical-align: middle;
        }
      }

      img {
        vertical-align: middle;
      }
      .mobile-teaser-line {
        line-height: 1.2rem;
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-left: 54px;
        margin-right: 16px;

        .ad-tag {
          height: 15px;
        }
        .ad-info {
          position: absolute;
          top: 0;
          right: 0;
          margin-right: 0;
        }

        .ad-subject {
          font-size: 13px;
          font-weight: bold;
        }
      }

      .ad-advertiser {
        overflow: hidden;
        text-overflow: clip;
        font-size: 14px;
        font-weight: bold;
      }

      .ad-description {
        font-size: 13px;
        margin-top: 10px;
        color: #6c6c6c;
      }
    }
  }
}
