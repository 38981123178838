.nb-incident-object {
  .nb-incident-row {
    display: flex;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .nb-incident-top-row {
    cursor: pointer;
  }

  .nb-icon-section {
    color: var(--blue);
    width: 2.5em;
    min-width: 2.5em;
    text-align: center;
  }

  .nb-title-section {
    flex: 1 0 0;
    padding-right: 1em;
    display: flex;
  }

  .nb-action-section {
    margin-right: 15px;
    width: 15em;
    min-width: 15em;
  }

  .nb-details-section {
    flex: 1 0 0;
  }
}

.nb-incident-chart {
  .nb-incident-metric-header {
    cursor: pointer;
    box-shadow: 0px 0px 3px 1px var(--dark);

    &.active {
      // box-shadow: none;
      box-shadow: 0px 0px 3px 1px var(--blue);
      // color: initial;
    }
  }
}

.nb-alert-run-point-array {
  display: flex;
  .nb-alert-run-point-element {
    flex: 1 0 auto;
    height: auto;
    padding: 4px;
    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }

  .status-alarm:before {
    background-color: var(--red);
    border: 1px solid var(--red);
  }

  .status-ok:before {
    background-color: var(--green);
    border: 1px solid var(--green);
  }

  .status-missing:before {
    background-color: var(--white);
    border: 1px dashed var(--gray);
  }

  .status-skipped:before {
    background-color: var(--gray);
    border: 1px solid var(--gray);
  }
}
