.query-builder {
  font-family: inherit;
}

.group--conjunctions {
  display: flex;
  align-items: center;
}

.group--drag-handler {
  top: 0px;
}
