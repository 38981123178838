.nb-input-container {
  @apply inline-block relative leading-none;

  .label {
    @apply text-xs text-subtitle mb-1;
  }

  .nb-input {
    @apply outline outline-1 outline-[#CED4DA] rounded-md p-2 w-full leading-normal;

    &:focus {
      @apply outline-active outline-3;
      box-shadow: 0 0 0 1px #2684ff;
    }
  }

  &.has-error {
    @apply mb-5;

    &:has(.error-message) > .nb-input {
      @apply outline-3 outline-danger shadow-none #{!important};
    }

    .error-message {
      @apply text-xxs text-danger absolute -bottom-4;
    }
  }
}
