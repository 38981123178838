@mixin custom-switch-mixin($_csm) {
  display: flex;
  $base: 2rem * $_csm;
  $innerPadding: 0.25rem;
  $onColor: #367bf3;

  margin: 0;

  .slider {
    position: relative;
    display: inline-block;
    width: $base * 2;
    height: $base;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: left;
    cursor: pointer;
    background-color: #adb5bd;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: $base + $innerPadding;
  }

  .slider::before {
    position: absolute;
    content: "";
    height: $base - $innerPadding;
    width: $base - $innerPadding;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    top: 50%;
  }

  .slider.disabled {
    opacity: 0.65;
  }

  input:checked + .slider {
    background-color: $onColor;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $onColor;
  }

  input:not(:checked) + .slider::before {
    transform: translateY(-50%);
    left: $innerPadding;
  }

  input:checked + .slider:before {
    -webkit-transform: translate($base, -50%);
    -ms-transform: translate($base, -50%);
    transform: translate($base, -50%);
  }

  input {
    width: 0;
    height: 0;
    opacity: 0;
  }
}

.custom-switch-large {
  @include custom-switch-mixin(1);
}

.custom-switch-medium {
  @include custom-switch-mixin(0.75);
}

.custom-switch-small {
  @include custom-switch-mixin(0.5);
}
