$accent: theme-color(
  $key: "primary",
);

.nb-interval-picker {
  padding-left: 1rem;
  padding-right: 1rem;
  .rc-slider {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }
  .rc-slider-track {
    background-color: $accent;
  }

  .rc-slider-handle,
  // more specific selector wins
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: $accent;
  }

  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    box-shadow: 0 0 0 5px lighten($color: $accent, $amount: 30%);
  }
}
