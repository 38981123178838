
.CalendarDay__selected_span {
  background: #c3d7fb;
  border: 1px solid #367BF3
}


.CalendarDay__selected {
  background: #367BF3;
  color: white;
  border:none;
}


.CalendarDay__selected:hover {
  background: #367BF3;
  color: white;
  border:none;
}



.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #c3d7fb;
  color: #484848;
  border:none;
}