.dropdown-btn {
  font-weight: 400;
  color: #212529;
  border-color: hsl(0, 0%, 80%);
  height: 3.5rem;

  &:hover,
  &:active,
  &:focus {
    background: #fff;
    color: #212529;
    border-color: hsl(0, 0%, 70%);
  }

  &:after {
    margin-left: 0.5rem;
  }

  &.btn-outline-secondary {
    font-weight: 400;
  }

  &.btn-outline-secondary:not(:disabled):not(.disabled):active {
    background: #fff;
    color: #212529;
    border-color: hsl(0, 0%, 70%);
  }

  .show & {
    &.btn-outline-secondary.dropdown-toggle,
    &.btn-outline-secondary:focus,
    &.btn-outline-secondary:active {
      background: #fff;
      color: #212529;
      border-color: #2684ff;
      box-shadow: 0 0 0 1px #2684ff;
    }
  }
}
