button.button {
  @apply rounded-[4px] font-medium leading-5 tracking-wide;

  &.sm {
    @apply py-1 px-2.5 text-sm;
  }

  &.md {
    @apply py-1.5 px-3;
  }

  &.lg {
    @apply py-3 px-3.5;
  }

  &.primary-button {
    @apply bg-[#367bf3] text-white;
  }

  &.secondary-button {
    color: #367bf3;
    background-color: white;
    border: 1px solid #367bf3;

    &:hover:not([disabled]) {
      background-color: #f5f9ff;
    }
  }

  &.tertiary-button {
    color: #367bf3;
    background-color: white;

    &:hover:not([disabled]) {
      background-color: #f5f9ff;
    }
  }

  &.primary-v2-button {
    @apply bg-[#367bf3] text-white font-light text-xs;
    @apply pt-1.5 pb-1.5 pl-4 pr-4 border border-[#367BF3];
    @apply flex items-center;
  }

  &.danger-button {
    @apply text-danger;

    &:hover:not([disabled]) {
      background-color: #fff5f6;
    }
  }

  &.icon-button {
    min-width: fit-content;

    &.sm {
      @apply p-1;
    }

    &.md {
      @apply p-1.5;
    }

    &.lg {
      @apply p-3;
    }
  }
}

// TEMP: Overwriting bootstrap button styles
.enableDesignRefresh .btn {
  &.btn-primary {
    @apply py-1.5 px-4 text-xs rounded-2xl font-semibold leading-5 tracking-wide bg-cosmic text-white border-transparent #{!important};

    &:is(:hover:not([disabled]), :focus) {
      @apply bg-cosmic+1 shadow-none #{!important};
    }

    &[disabled] {
      @apply opacity-100 border-transparent bg-disabled #{!important};
    }
  }

  &:is(.btn-secondary, .btn-outline-primary) {
    @apply py-1.5 px-4 text-xs rounded-2xl font-semibold leading-5 tracking-wide text-interstellar bg-white border border-[#717680] #{!important};

    &:is(:hover:not([disabled]), :focus) {
      @apply shadow-none bg-cosmic-3 border border-cosmic+1 #{!important};
    }

    &[disabled] {
      @apply opacity-100 border border-disabled text-disabled #{!important};
    }
  }
}

.enableDesignRefresh button.button {
  @apply font-semibold leading-5 tracking-wide #{!important};

  &.sm {
    @apply py-1.5 px-4 text-xs rounded-2xl #{!important};
  }

  &.md {
    @apply py-2.5 px-4 text-xs rounded-[20px] #{!important};
  }

  &.lg {
    @apply py-3.5 px-4 text-lg rounded-3xl #{!important};
  }

  &.primary-button {
    @apply bg-cosmic text-white #{!important};

    i[class^="fa-"] {
      @apply text-white #{!important};
    }

    &:is(:hover:not([disabled]), :focus) {
      @apply bg-cosmic+1 #{!important};
    }

    &[disabled] {
      @apply bg-disabled #{!important};
    }
  }

  &.secondary-button {
    @apply text-interstellar bg-white border border-[#717680] #{!important};

    i[class^="fa-"] {
      @apply text-cosmic #{!important};
    }

    &:is(:hover:not([disabled]), :focus) {
      @apply bg-cosmic-3 border border-cosmic+1 #{!important};
    }

    &[disabled] {
      @apply border border-disabled text-disabled #{!important};
    }
  }

  &.tertiary-button {
    @apply text-cosmic bg-transparent #{!important};

    i[class^="fa-"] {
      @apply text-cosmic #{!important};
    }

    &:is(:hover:not([disabled]), :focus) {
      @apply bg-cosmic-3 text-cosmic+1 #{!important};
    }

    &[disabled] {
      @apply text-disabled #{!important};
    }
  }
}
