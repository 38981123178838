.nb-has-control-center {
  display: flex;

  .nb-control-center {
    height: 100%;

    .control-label {
      font-weight: 500;
    }
  }

  .nb-control-center-companion-content {
    flex-grow: 1;
    padding-bottom: 15px;
    height: 100%;
  }
}

.nb-control-bar {
  .nb-control-bar-element-title {
    font-weight: 500;
  }
}
