.onboarding-impersonate-dropdown {
  margin-top: 60px;
  margin-left: 33px;
  width: 224px;
}

.right-aligned {
  left: initial;
}

.onboarding-wizard-container {
  background-color: #f4f7fe;
}

.onboarding-wizard-container > .rsw_2Y {
  flex: 0 0 75%;
  max-width: 75%;
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: 0;
}

.onboarding-wizard-container > .rsw_2Y > .rsw_3G {
  display: block !important;
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-animation-fill-mode: none;
  animation-fill-mode: none;
  margin-top: 140px;
  margin-bottom: 40px;
}

.onboarding-nav {
  background-color: white;
  width: 290px;
}

.nav-image-element {
  width: 257px;
  margin-top: 8px;
  display: block;
  cursor: pointer;
}

.v-nav-bar {
  margin-left: 33px;
  margin-top: 160px;
}

.onboarding-step {
  background: white;
  padding: 50px;
  border-radius: 48px;
}

.onboarding-controls-secondary {
  position: fixed;
  bottom: 86px;
  height: 43px;
  margin-left: 2px;
  width: 100%;
  background-color: white;
}

.onboarding-controls {
  position: fixed;
  bottom: 0;
  height: 86px;
  margin-left: 2px;
  width: 100%;
  background-color: white;
}

.onboarding-instruction-links {
  position: fixed;
  top: 80px;
  height: 40px;
  width: 100%;
  right: 20px;
}

.blue {
  color: #467dec;
}

.purple {
  color: #5c5ed1;
}

.blue-border {
  border-color: #467dec !important;
}

.purple-border {
  border-color: #5c5ed1 !important;
}
