@import "~rc-tooltip/assets/bootstrap_white.css";

.rc-tooltip {
  opacity: 1 !important;

  .nb-tooltip-inner {
    width: 20em;
  }
}

.nb-tooltip-children i {
  opacity: 0.9;
  font-size: 85%;
}
