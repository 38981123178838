/* Generic font */
@font-face {
  font-family: "Commons Pro";
  src: url("fonts/EOT/TT Commons Pro EOT 3-200/TT_Commons_Pro_Normal.eot");
  src: url("fonts/EOT/TT Commons Pro EOT 3-200/TT_Commons_Pro_Normal.eot")
      format("embedded-opentype"),
    url("fonts/WOFF2/TT Commons Pro WOFF2 3-200/TT_Commons_Pro_Normal.woff2")
      format("woff2"),
    url("fonts/WOFF/TT Commons Pro WOFF 3-200/TT_Commons_Pro_Normal.woff")
      format("woff"),
    url("fonts/TTF/TT Commons Pro TrueType 3-200/TT Commons Pro Normal.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Commons Pro";
  src: url("fonts/EOT/TT Commons Pro EOT 3-200/TT_Commons_Pro_DemiBold.eot");
  src: url("fonts/EOT/TT Commons Pro EOT 3-200/TT_Commons_Pro_DemiBold.eot")
      format("embedded-opentype"),
    url("fonts/WOFF2/TT Commons Pro WOFF2 3-200/TT_Commons_Pro_DemiBold.woff2")
      format("woff2"),
    url("fonts/WOFF/TT Commons Pro WOFF 3-200/TT_Commons_Pro_DemiBold.woff")
      format("woff"),
    url("fonts/TTF/TT Commons Pro TrueType 3-200/TT Commons Pro DemiBold.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Commons Pro";
  src: url("fonts/EOT/TT Commons Pro EOT 3-200/TT_Commons_Pro_Italic.eot");
  src: url("fonts/EOT/TT Commons Pro EOT 3-200/TT_Commons_Pro_Italic.eot")
      format("embedded-opentype"),
    url("fonts/WOFF2/TT Commons Pro WOFF2 3-200/TT_Commons_Pro_Italic.woff2")
      format("woff2"),
    url("fonts/WOFF/TT Commons Pro WOFF 3-200/TT_Commons_Pro_Italic.woff")
      format("woff"),
    url("fonts/TTF/TT Commons Pro TrueType 3-200/TT Commons Pro Italic.ttf")
      format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Commons Pro";
  src: url("fonts/EOT/TT Commons Pro EOT 3-200/TT_Commons_Pro_DemiBold_Italic.eot");
  src: url("fonts/EOT/TT Commons Pro EOT 3-200/TT_Commons_Pro_DemiBold_Italic.eot")
      format("embedded-opentype"),
    url("fonts/WOFF2/TT Commons Pro WOFF2 3-200/TT_Commons_Pro_DemiBold_Italic.woff2")
      format("woff2"),
    url("fonts/WOFF/TT Commons Pro WOFF 3-200/TT_Commons_Pro_DemiBold_Italic.woff")
      format("woff"),
    url("fonts/TTF/TT Commons Pro TrueType 3-200/TT Commons Pro DemiBold Italic.ttf")
      format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Commons Pro";
  src: url("fonts/EOT/TT Commons Pro EOT 3-200/TT_Commons_Pro_Light.eot");
  src: url("fonts/EOT/TT Commons Pro EOT 3-200/TT_Commons_Pro_Light.eot")
      format("embedded-opentype"),
    url("fonts/WOFF2/TT Commons Pro WOFF2 3-200/TT_Commons_Pro_Light.woff2")
      format("woff2"),
    url("fonts/WOFF/TT Commons Pro WOFF 3-200/TT_Commons_Pro_Light.woff")
      format("woff"),
    url("fonts/TTF/TT Commons Pro TrueType 3-200/TT Commons Pro Light.ttf")
      format("truetype");
  font-weight: 100;
  font-style: normal;
}

/* Mono-space font for tables */
@font-face {
  font-family: "Commons Pro Mono";
  src: url("fonts/EOT/TT Commons Pro Mono EOT 3-200/TT_Commons_Pro_Mono_Regular.eot");
  src: url("fonts/EOT/TT Commons Pro Mono EOT 3-200/TT_Commons_Pro_Mono_Regular.eot")
      format("embedded-opentype"),
    url("fonts/WOFF2/TT Commons Pro Mono WOFF2 3-200/TT_Commons_Pro_Mono_Regular.woff2")
      format("woff2"),
    url("fonts/WOFF/TT Commons Pro Mono WOFF 3-200/TT_Commons_Pro_Mono_Regular.woff")
      format("woff"),
    url("fonts/TTF/TT Commons Pro Mono TrueType 3-200/TT Commons Pro Mono Regular.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Commons Pro Mono";
  src: url("fonts/EOT/TT Commons Pro Mono EOT 3-200/TT_Commons_Pro_Mono_DemiBold.eot");
  src: url("fonts/EOT/TT Commons Pro Mono EOT 3-200/TT_Commons_Pro_Mono_DemiBold.eot")
      format("embedded-opentype"),
    url("fonts/WOFF2/TT Commons Pro Mono WOFF2 3-200/TT_Commons_Pro_Mono_DemiBold.woff2")
      format("woff2"),
    url("fonts/WOFF/TT Commons Pro Mono WOFF 3-200/TT_Commons_Pro_Mono_DemiBold.woff")
      format("woff"),
    url("fonts/TTF/TT Commons Pro Mono TrueType 3-200/TT Commons Pro Mono DemiBold.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Commons Pro Mono";
  src: url("fonts/EOT/TT Commons Pro Mono EOT 3-200/TT_Commons_Pro_Mono_Italic.eot");
  src: url("fonts/EOT/TT Commons Pro Mono EOT 3-200/TT_Commons_Pro_Mono_Italic.eot")
      format("embedded-opentype"),
    url("fonts/WOFF2/TT Commons Pro Mono WOFF2 3-200/TT_Commons_Pro_Mono_Italic.woff2")
      format("woff2"),
    url("fonts/WOFF/TT Commons Pro Mono WOFF 3-200/TT_Commons_Pro_Mono_Italic.woff")
      format("woff"),
    url("fonts/TTF/TT Commons Pro Mono TrueType 3-200/TT Commons Pro Mono Italic.ttf")
      format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Commons Pro Mono";
  src: url("fonts/EOT/TT Commons Pro Mono EOT 3-200/TT_Commons_Pro_Mono_DemiBold_Italic.eot");
  src: url("fonts/EOT/TT Commons Pro Mono EOT 3-200/TT_Commons_Pro_Mono_DemiBold_Italic.eot")
      format("embedded-opentype"),
    url("fonts/WOFF2/TT Commons Pro Mono WOFF2 3-200/TT_Commons_Pro_Mono_DemiBold_Italic.woff2")
      format("woff2"),
    url("fonts/WOFF/TT Commons Pro Mono WOFF 3-200/TT_Commons_Pro_Mono_DemiBold_Italic.woff")
      format("woff"),
    url("fonts/TTF/TT Commons Pro Mono TrueType 3-200/TT Commons Pro Mono DemiBold Italic.ttf")
      format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Commons Pro Mono";
  src: url("fonts/EOT/TT Commons Pro Mono EOT 3-200/TT_Commons_Pro_Mono_Light.eot");
  src: url("fonts/EOT/TT Commons Pro Mono EOT 3-200/TT_Commons_Pro_Mono_Light.eot")
      format("embedded-opentype"),
    url("fonts/WOFF2/TT Commons Pro Mono WOFF2 3-200/TT_Commons_Pro_Mono_Light.woff2")
      format("woff2"),
    url("fonts/WOFF/TT Commons Pro Mono WOFF 3-200/TT_Commons_Pro_Mono_Light.woff")
      format("woff"),
    url("fonts/TTF/TT Commons Pro Mono TrueType 3-200/TT Commons Pro Mono Light.ttf")
      format("truetype");
  font-weight: 100;
  font-style: normal;
}
