.border-left {
  border-left: 1px solid #dfdfdf !important;
}

.border-right {
  border-right: 1px solid #dfdfdf !important;
}

.border-bottom {
  border-bottom: 1px solid #dfdfdf !important;
}

.dx-g-bs4-table-container {
  table.dx-g-bs4-table {
    .dx-g-bs4-table-sticky {
      // Needs to be overridden because default is 500
      z-index: 2;
    }
    .dx-g-bs4-fixed-cell:not(th),
    .dx-g-bs4-fixed-group-cell:not(th) {
      @apply bg-white;
      z-index: 1;
    }
    .dx-g-bs4-table-invisible-row {
      @apply hidden;
    }

    thead {
      tr {
        & > th {
          @apply bg-[#F7F9FC] border-b-0 border-left;

          &:last-child {
            @apply border-right;
          }
        }

        // The first checkbox column in the header
        &:first-child {
          & > th:first-child {
            background-color: #F7F9FC !important;
          }
        }
      }
    }

    tbody {
      tr {
        &.table-active > td {
          background-color: #d0e3f1 !important;
        }

        & > td {
          @apply border-left;

          &:last-child {
            @apply border-right;
          }
        }

        &:last-child {
          td {
            @apply border-bottom;
          }
        }
      }
    }
  }
}
