span.grippy {
  float: left;
  content: "......";
  width: 1em;
  height: 3em;
  // display: inline-block;
  overflow: hidden;
  line-height: 5px;
  padding: 2px 4px;
  cursor: move;
  vertical-align: middle;
  margin-top: -0.25em;
  margin-right: 0.5em;
  font-size: 12px;
  font-family: sans-serif;
  letter-spacing: 2px;
  color: #cccccc;
  text-shadow: 0px 0 1px black;
}

span.grippy::after {
  content: ".. .. .. ..";
}
